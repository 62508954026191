import React from "react"

import "./pricing-table.css"


const PricingTable = ({ content, color }) => {
    const [openTab, setOpenTab] = React.useState(1);

  return (
    <section className="block-v pv-[10vw] md:pv-[5vw] hidden" >
      <div className="text-center ">
        <span className="text-[1.45vw] text-helvetica-light block">{content.preHeadline}</span>
        <h2 className="text-[4.5vw]  mb-[3.4vw]">{content.headline}</h2>
      
        <>
      <div className="flex flex-wrap">
        <div className="w-full ">
          <ul
            className="flex flex-row flex-wrap pt-3 pb-0 mb-0 list-none container-sm"
            role="tablist"
          >
            <li className="flex-auto mr-2 -mb-px text-center last:mr-0 tab">
              <a
                className={
                  " uppercase px-6 py-4 pb-3 text-[1.01vw]  block leading-normal " +
                  (openTab === 1
                    ? "text-dark-blue  bg-yellow text-helvetica" 
                    : "text-dark-blue bg-transparent text-helvetica")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(1);
                }}
                data-toggle="tab"
                href="#link1"
                role="tablist"
              >
                Pricing
              </a>
            </li> 
            <li className="flex-auto mr-2 -mb-px text-center last:mr-0 tab">
              <a
                className={
                    " uppercase px-6 py-4 pb-3 text-[1.01vw]  block leading-normal " +
                  (openTab === 2
                    ? "text-dark-blue  bg-yellow text-helvetica" 
                    : "text-dark-blue bg-transparent text-helvetica")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(2);
                }}
                data-toggle="tab"
                href="#link2"
                role="tablist"
              >
                 Availability
              </a>
            </li>
            <li className="flex-auto mr-2 -mb-px text-center last:mr-0 tab">
              <a
                className={
                    " uppercase px-6 py-4 pb-3 text-[1.01vw]  block leading-normal " +
                  (openTab === 3
                    ? "text-dark-blue  bg-yellow text-helvetica" 
                    : "text-dark-blue bg-transparent text-helvetica")
                }
                onClick={e => {
                  e.preventDefault();
                  setOpenTab(3);
                }}
                data-toggle="tab"
                href="#link3"
                role="tablist"
              >
                 Inquire
              </a>
            </li>
          </ul>
          <div className="relative flex flex-col min-w-0 break-words bg-[#EBEBEB] text-dark-blue text-helvetica w-full mb-6 ">
            <div className="flex-auto px-4 py-5 text-left ">
              <div className="tab-content tab-space container-sm text-[1.04vw]">
                <div className={openTab === 1 ? "block overflow-x-auto scroller" : "hidden"} id="link1">
                  <>
                  <div dangerouslySetInnerHTML={{__html:content.priceTable}}></div>
                
                  </>
                </div>
                <div className={openTab === 2 ? "block overflow-x-auto scroller" : "hidden"} id="link2">
                  <>
                  <div dangerouslySetInnerHTML={{__html:content.availabilityTable}}></div>
                  
                  </>
                </div>
                <div className={openTab === 3 ? "block" : "hidden"} id="link3">
                  <>
                  <div dangerouslySetInnerHTML={{__html:content.inquireTab}}></div>
                  
                  </>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
      
       
      </div>
    </section>
  )
};

export default function TabsRender({content}) {
    return (
     
      
       <PricingTable content={content} color="yellow" />
     
    );
  }
